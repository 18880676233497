import React, { FC } from "react";
import { BsInfoCircle } from "react-icons/bs";

import Panel from "./Panel";

const WarningPanel: FC = () => {
  return (
    <Panel
      // initiallyDeployed
      title={
        <>
          <BsInfoCircle className="text-muted" /> Details
        </>
      }
    >
    <p>
      The network was collected via snowballing starting from bestsellers in socially related categories on Amazon.fr.
    </p>
    <p>
      Data was collected from October 28, 2023 to November 4, 2023, experiences at a later date may differ.
    </p>

    <p>
     The network depicts non-personalized recommendations, the experience of a logged-in user may differ.
    </p>

    <p>
     For enhanced accessibility, only nodes from relevant categories and with a degree larger than 5 are displayed.
    </p>

    <p>
    The membership of a book to a given community is not of the author’s will, but the result of Amazon's algorithms.
    </p>
    <p>
     The name of the communities are indicative and may not accurately reflect the content of every book.
    </p>
    
    <p>
     Amazon's book categories may change over time.
    </p>

    <p>
      The data were collected and analyzed by <a target="_blank" rel="noreferrer" href="https://paulbouchaud.com">Paul Bouchaud</a>. For further details please refer to the article <a target="_blank" rel="noreferrer" href="https://hal.science/hal-04308081">"Browsing Amazon's Books bubble"</a>.
    </p>

    <p>
       This web application, relying on <a target="_blank" rel="noreferrer" href="https://www.sigmajs.org">sigma.js</a>, is hosted on the <a target="_blank" rel="noreferrer" href="https://multivacplatform.org/">Multivac</a> big-data platform &hearts;.
    </p>

    </Panel>
  );
};

export default WarningPanel;
