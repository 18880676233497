import React, { FC } from "react";
import { BsInfoCircle } from "react-icons/bs";

import Panel from "./Panel";

const DescriptionPanel: FC = () => {
  return (
    <Panel
      initiallyDeployed
      title={
        <>
          <BsInfoCircle className="text-muted" /> Description
        </>
      }
    >
      <p>
        This map represents a <i>network</i> of Amazon book recommendations. Each <i>node</i> represents a book, two nodes are linked if Amazon.fr recommends one on the page of the other.
      </p>

      <p>
        We identify <i>communities</i> of books often co-recommended by Amazon.
      </p>

      <p>
        Size reflects the number of recommendations books receive, and the color their community.
      </p>

      <p>
      Hover over a book to see its title, author, category and community.
      </p>

      <p>
      Click on a book to open the corresponding Amazon webpage.
      </p>

      <p>
       Filter books by community or Amazon category bellow.
      </p>
    </Panel>
  );
};

export default DescriptionPanel;
