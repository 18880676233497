import React from "react";
import ReactDOM from "react-dom";
import Sigma from "sigma";
import "./styles.css";
import Root from "./views_civic/Root";

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById("root"),
);
